import * as React from "react";

import { GatsbyImage, getImage, StaticImage, withArtDirection } from "gatsby-plugin-image";
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { hero, hero_wrapper, hero_title, hero_titleWrapper, hero_title_h1, 
    hero_title_h2, hero_title_h3, hero_title_h4, hero_image, hero_imageAccent, hero_imageMain,
    imageOverlay, imageOverlay_sm, imageOverlay_lg, headline, headline_red 
    } from './homepage-hero.module.scss';


const HomepageHero = ({data}) => {
    const images = withArtDirection(
        getImage(data.bannerImage), 
        [
            {
                media: "(max-width: 750px)",
                image: getImage(data.smallBannerImage),
            },
        ]
    )


    
    return (
        <section className={hero}>
            <div className={hero_wrapper}>
                <div className={hero_title}>
                    <div className={hero_titleWrapper}>
                        <div className={`${hero_title_h1}`}>
                            <h2>{data.heading1}</h2>
                        </div>
                        <div className={hero_title_h2}>
                            <h2>{data.heading2}</h2>
                        </div>
                        <div className={hero_title_h3}>
                            <h2>{data.heading3}</h2>
                        </div>
                        <div className={hero_title_h4}>
                            {renderRichText(data.heading4)}
                        </div>
                    </div>
                </div>
                <div className={hero_image}>
                    <div className={hero_imageAccent}>
                        <StaticImage src="../../assets/images/hero-heart-sm.png" alt="" 
                            className={`${imageOverlay} ${imageOverlay_sm}`}  loading="eager"/>
                        <StaticImage src="../../assets/images/hero-heart.png" alt="" 
                            className={`${imageOverlay} ${imageOverlay_lg}`}  loading="eager"/>
                        <div className={headline}>
                            <h3>Connect.<span className={headline_red}>Care.</span>Compete.</h3>
                        </div>
                    </div>
                        <GatsbyImage image={images} alt={data.bannerImageAlt} className={hero_imageMain}  loading="eager"/>
                </div>
            </div>
        </section>

    )

}

export default HomepageHero