import * as React from "react";

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Link } from 'gatsby';

import { FaArrowCircleRight } from "@react-icons/all-files/fa/FaArrowCircleRight";

import {blSection, blSection_wrapper, blSection_image, blSection_imageWrapper, 
    imageOverlay, blSection_title, blSection_titleWrapper, blSection_list, 
    itemTitle, blSection_link, blSection_link_text} from './bulleted-list-section.module.scss';

const BulletedListSection = ({data}) => {

    const image = getImage(data.sectionImage)
    
    return (
        <section className={blSection}>
            <div className={blSection_wrapper}>
                <div className={blSection_image}>
                    <div className={blSection_imageWrapper}>
                        <StaticImage src="../../assets/images/ecg-accent.png" alt="" className={imageOverlay} />
                        <GatsbyImage image={image} alt={data.name} />
                    </div>
                </div>
                <div className={blSection_title}>
                    <div className={blSection_titleWrapper}>
                        {renderRichText(data.title)}
                    </div>
                </div>
                <div className={blSection_list}>
                    <div>
                        <ul>
                            {data.bulletItemsList.map((item) => (
                                <li key={item.id}>
                                    <span className={itemTitle}>{item.title} - </span>{item.body}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                { data.pageLink!== '#' ?
                    <div className={blSection_link}>
                        <Link to={data.pageLink}>
                            <span className={blSection_link_text}>{data.pageLinkText}</span><FaArrowCircleRight /></Link>
                    </div>
                    : 
                    ''
                }
            </div>
            
        </section>
    )

}

export default BulletedListSection