// extracted by mini-css-extract-plugin
export var fadeInBottom = "text-image-section-module--fade-in-bottom--PzebM";
export var fadeOutTop = "text-image-section-module--fade-out-top--ocQwz";
export var textImage = "text-image-section-module--textImage--omlMU";
export var textImage_bodyWrapper = "text-image-section-module--textImage_bodyWrapper--pyDpo";
export var textImage_image = "text-image-section-module--textImage_image--BWKIc";
export var textImage_link = "text-image-section-module--textImage_link--Cl04L";
export var textImage_link_text = "text-image-section-module--textImage_link_text--s+4ch";
export var textImage_text = "text-image-section-module--textImage_text--7TlGi";
export var textImage_titleWrapper = "text-image-section-module--textImage_titleWrapper--nD7T9";
export var textImage_wrapper = "text-image-section-module--textImage_wrapper--lgXz-";