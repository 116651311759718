// extracted by mini-css-extract-plugin
export var blSection = "bulleted-list-section-module--blSection--z0+OT";
export var blSection_image = "bulleted-list-section-module--blSection_image--q79O+";
export var blSection_imageWrapper = "bulleted-list-section-module--blSection_imageWrapper--sxUya";
export var blSection_link = "bulleted-list-section-module--blSection_link--NMBuC";
export var blSection_link_text = "bulleted-list-section-module--blSection_link_text--PYVjK";
export var blSection_list = "bulleted-list-section-module--blSection_list--WkNbp";
export var blSection_title = "bulleted-list-section-module--blSection_title--w537U";
export var blSection_titleWrapper = "bulleted-list-section-module--blSection_titleWrapper--sOFW8";
export var blSection_wrapper = "bulleted-list-section-module--blSection_wrapper--DrdGB";
export var fadeInBottom = "bulleted-list-section-module--fade-in-bottom--qZOB0";
export var fadeOutTop = "bulleted-list-section-module--fade-out-top--xyg2t";
export var imageOverlay = "bulleted-list-section-module--imageOverlay--52ea4";
export var itemTitle = "bulleted-list-section-module--itemTitle--ItsdN";