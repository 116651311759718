// extracted by mini-css-extract-plugin
export var fadeInBottom = "homepage-hero-module--fade-in-bottom--kzhBS";
export var fadeOutTop = "homepage-hero-module--fade-out-top--D5fRu";
export var headline = "homepage-hero-module--headline--1wiIZ";
export var headline_red = "homepage-hero-module--headline_red--BLQst";
export var hero = "homepage-hero-module--hero--hNQ7g";
export var hero_image = "homepage-hero-module--hero_image--juio-";
export var hero_imageAccent = "homepage-hero-module--hero_imageAccent--Z6miv";
export var hero_imageMain = "homepage-hero-module--hero_imageMain--HYAVV";
export var hero_title = "homepage-hero-module--hero_title--FuXpu";
export var hero_titleWrapper = "homepage-hero-module--hero_titleWrapper--Cu-mK";
export var hero_title_h1 = "homepage-hero-module--hero_title_h1--W1Lhl";
export var hero_title_h2 = "homepage-hero-module--hero_title_h2--d9HmC";
export var hero_title_h3 = "homepage-hero-module--hero_title_h3--6CeZc";
export var hero_title_h4 = "homepage-hero-module--hero_title_h4--hSkMU";
export var hero_wrapper = "homepage-hero-module--hero_wrapper--svwjJ";
export var imageOverlay = "homepage-hero-module--imageOverlay--svQSN";
export var imageOverlay_lg = "homepage-hero-module--imageOverlay_lg--bftxO";
export var imageOverlay_sm = "homepage-hero-module--imageOverlay_sm--mR2ud";