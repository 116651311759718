import * as React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Link } from 'gatsby';

import { FaArrowCircleRight } from "@react-icons/all-files/fa/FaArrowCircleRight";

import { textImage, textImage_wrapper, textImage_text, textImage_titleWrapper, 
    textImage_bodyWrapper, textImage_link, textImage_link_text,
    textImage_image } from './text-image-section.module.scss';


const TextImageSection = ({data}) => {

    const image = getImage(data.sectionImage)

    return (
        <section className={textImage}>
            <div className={textImage_wrapper}>
                <div className={textImage_text}>
                    <div className={textImage_titleWrapper}>
                        {renderRichText(data.title)}
                    </div>
                    <div className={textImage_bodyWrapper}>
                        <p>
                            {data.bodyText.bodyText}
                        </p>
                    </div>
                    { data.href !=='#' ?
                        <div className={textImage_link}>
                            <Link to={data.href}>
                                <span className={textImage_link_text}>{data.linkText}</span><FaArrowCircleRight /></Link>
                        </div>
                        :
                        ""
                    }
                </div>
                <div className={textImage_image}>
                    <div>
                        <GatsbyImage
                            image={image} alt=""
                        />
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default TextImageSection