import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import BulletedListSection from "../components/BulletedListSection";
import ContactForm from "../components/ContactForm";
import HomepageHero from "../components/HomepageHero";
import Layout from "../components/Layout/layout";
import TextBlockSection from "../components/TextBlockSection";
import TextImageSection from "../components/TextImageSection";
import { Seo } from '../components/Seo/seo';


const Homepage = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulHomepage {
      nodes {
        id
        description
        name
        homepageHero {
          id
          heading1
          heading2
          heading3
          heading4 {
            raw
          }
          bannerImage {
            gatsbyImageData(
              width: 1440
              quality: 100
              placeholder: BLURRED
              layout: FULL_WIDTH
            )
          }
          bannerImageAlt
          smallBannerImage {
            gatsbyImageData(width: 990, quality: 100, placeholder: BLURRED, 
            layout: FULL_WIDTH)
          }
        }
        textBlockSection {
          id
          name
          title {
            raw
          }
          body {
            body
          }
          colorScheme
        }
        bulletedSection {
          id
          name
          title {
            raw
          }
          bulletItemsList {
            id
            title
            body
          }
          pageLinkText
          pageLink
          sectionImage {
            gatsbyImageData(width: 405, quality: 100, placeholder: BLURRED, 
            layout: FULL_WIDTH)
          }
        }
        textImageSection {
          id
          name
          title {
            raw
          }
          bodyText {
            bodyText
          }
          href
          linkText
          textLeft
          sectionImage {
            gatsbyImageData(width: 290, quality: 100, placeholder: BLURRED, 
            layout: FIXED)
          }
        }
      }
    }
    allContentfulLocation {
      edges {
        node {
          phoneNumber
        }
      }
    }
  }
`)

  const {textBlockSection, bulletedSection, homepageHero, textImageSection} = data.allContentfulHomepage.nodes[0]
  const { phoneNumber } = data.allContentfulLocation.edges[0].node

  return (
    <>
      <Layout>
          <HomepageHero data={homepageHero} />
          <TextBlockSection data={textBlockSection}/>
          <BulletedListSection data={bulletedSection} />
          <TextImageSection data={textImageSection} />
          <ContactForm data={phoneNumber} />
      </Layout>
    </>

  )
}


export const Head = () => (
  <Seo
    title="Home"
  />

)

export default Homepage